/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Badge, Divider, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle, Apartment, Flag,
    Inventory,
    LocalActivity,
    LocalMall,
    LocalPolice,
    LocalShipping, MilitaryTech,
    Money, PhoneAndroid,
    QrCode2,
    ShoppingCart, Signpost, ThumbUp, Upload
} from "@mui/icons-material";
import {useState} from "react";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";


const Formulario_pedido_detalle = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Estado'} dato={'estado'}
                                   {...props} opciones={['Nuevo', 'Empacado', 'Enviado']}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Producto'} dato={'producto'}  {...props}
                                  editable={false}


                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombres y Apellidos'} dato={'nombre'}
                                  requerido={'El nombre es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Badge} nombre={'# Identificacion'} dato={'cc'}
                                  requerido={'El numero de identificacion es requerido'}

                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Num Celular'} dato={'celular'}
                                  requerido={'El numero celular es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Flag} nombre={'Departamento'} dato={'departamento'}
                                  requerido={'El departamento es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Apartment} nombre={'Ciudad'} dato={'ciudad'}
                                  requerido={'La ciudad es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Signpost} nombre={'Direccion'} dato={'direccion'}
                                  requerido={'La direccion es requerida'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Indicaciones Adicionales'} dato={'indicaciones'} lineas={4}
                                  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_pedido_detalle