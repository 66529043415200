import axios from "axios";
import {URLAlMACENAMIENTO, URLAPISUBIRUNAIMAGEN} from "../Constantes";

export const subirImagenHosting = ({file}) => {
    const formData = new FormData();
    formData.append('img', file);

    return new Promise(resolve => {

        if (typeof file === 'string') {
            return resolve({res: true, data: file})
        }else{
            axios.post(URLAPISUBIRUNAIMAGEN, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {

                    let url = URLAlMACENAMIENTO + response.data.file_path
                    return resolve({res: true, data: url})
                    // Handle successful upload response
                })
                .catch(error => {
                    return resolve({res: false, data: error.message})
                });
        }



    })
}