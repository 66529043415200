/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    Inventory, Layers,
    LocalActivity,
    LocalMall,
    LocalPolice,
    LocalShipping, MilitaryTech,
    Money,
    QrCode2,
    ShoppingCart, ThumbUp, Upload
} from "@mui/icons-material";
import {useState} from "react";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";


const Formulario_producto_kiosco = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCrop dato={'img'}  ancho={1} alto={1}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Nombre del producto'} dato={'nombre'}  {...props}
                                  requerido={'El nombre requerido'}


                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props} lineas={6}
                                  requerido={'La descripcion es requerida'}

                    />
                </Grid>


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Layers} nombre={'# en stock'} dato={'stock'} type={'number'}  {...props}
                                  requerido={'El Numero de en stock es requerido'}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={MilitaryTech} nombre={'Valor en Medallas'} dato={'numMedallas'}
                                  type={'number'}  {...props}
                                  requerido={'El Numero de medallas es requerido'}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={MilitaryTech} nombre={'Valor en Pesos'} dato={'valor'}  {...props}
                                  requerido={'El Valor  es requerido'}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_producto_kiosco