/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Box, Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {Gavel, Inventory, ManageSearch, ThumbUpAltOutlined} from "@mui/icons-material";
import {contarPropiedadSumaWheres} from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Referencia_Detalle from "../Dialogos/Dialogo_Referencia_Detalle";
import Dialogo_Reto_Detalle from "../Dialogos/Dialogo_Reto_Detalle";
import Dialogo_Evidencia_Detalle from "../Dialogos/Dialogo_Evidencia_Detalle";
import {contarColeccionWhere} from "../../Servicios/BD/Contadores/contarColeccionWhere";
import Dialogo_Producto_Kiosco_Detalle from "../Dialogos/Dialogo_Producto_Kiosco_Detalle";

const Seccion_Kiosco = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'productosKiosco',
        filtroInicial: 'fecha',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Producto_Kiosco_Detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de producto'
    })


    const BotonesTitulo = [
        <Button
            startIcon={<Inventory/>}
            onClick={() => abrir()}
        >
            Nuevo
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
        }

    }


    useEffect(() => {


        contarColeccion({coleccion: 'productosKiosco'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })


        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Kiosco'} textoComplementario={'en kiosco'}
                                      textoResaltado={`${numProductos} Productos`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numProductos}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Kiosco

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
        tipo: 'fechaDato'
    },
    {
        nombre: 'En Stock',
        direccion: 'left',
        propiedad: 'stock',
    },
    {
        nombre: 'Valor Medallas',
        direccion: 'left',
        propiedad: 'numMedallas',
    },
    {
        nombre: 'Valor Pesos',
        direccion: 'right',
        propiedad: 'valor',
        tipo: 'moneda'
    },

]


const datosCabeceraCell = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
        tipo: 'fechaDato'
    },
    {
        nombre: 'En Stock',
        direccion: 'left',
        propiedad: 'stock',
    },
    {
        nombre: 'Valor Medallas',
        direccion: 'right',
        propiedad: 'numMedallas',
    },
]

const botonesTabla = [
    {
        nombre: 'Ver',
        icono: ManageSearch,
        id: 'ver'
    }

]



