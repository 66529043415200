/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_detalle_referenciado from "../Formularios/Formulario_detalle_referenciado";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import Formulario_reto_detalle from "../Formularios/Formulario_reto_detalle";
import Formulario_evidencia_detalle from "../Formularios/Formulario_evidencia_detalle";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {Delete} from "@mui/icons-material";

const Dialogo_Evidencia_Detalle = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            obj.estado = 'Entregado'
            guardarDoc('evidencias', obj).then((dox) => {
                if (dox.res) {
                    actualizarNumMedallas(obj)
                }
                cerrarCargador()
            })

        })


    }

    const actualizarNumMedallas = (ent) => {

        let idUsuario = ent.idUsuario

        obtenerDoc('usuarios', idUsuario).then((dox) => {
            if (dox.res) {
                console.log(dox.data)
                let numActual = dox.data.numMedallas ? parseInt(dox.data.numMedallas) : 0
                let numSubir = numActual + parseInt(ent.numEntregar)

                actualizarDoc('usuarios', idUsuario, {numMedallas: numSubir}).then((des) => {
                    if (des.res) {
                        alert('Medallas entregadas con exito')
                        realizado()
                        cerrar()
                    }
                    cerrarCargador()
                })
            }
        })

    }

    const borrar = () => {
        abrirCargador('Borrando Potencial')
        borrarDoc('evidencias', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Formulario_reto_detalle props={props} editable={false}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_evidencia_detalle props={props} isImagen={datos.tipo === 'Imagen'} img={datos.img}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    disabled={datos && datos.estado === 'Entregado'}
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Entregar Medallas</Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Evidencia</Button>
                }
            </Grid>


        </Grid>
    )

}
export default Dialogo_Evidencia_Detalle