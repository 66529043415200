/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";

import {
    AccountCircle, Checklist,
    EmojiEmotions,
    GavelOutlined, GroupAdd,
    LocalActivity,
    LocalShipping,
    PointOfSale,
    Receipt, ShoppingCart, Storefront, ThumbUpOutlined
} from "@mui/icons-material";
import logo from '../Recursos/logo_color.svg'
import logo_redondo from '../Recursos/logo_redondo.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import {funSalir} from "../Servicios/Auth/funSalir";
import Seccion_Potenciales from "./Secciones/Seccion_Potenciales";
import Seccion_Subastas from "./Secciones/Seccion_Subastas";
import Seccion_Venta_Tickets from "./Secciones/Seccion_Venta_Tickets";
import Seccion_Caja from "./Secciones/Seccion_Caja";
import Seccion_Tickets from "./Secciones/Seccion_Tickets";
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {agregarItemArrayDocVerificar} from "../Servicios/BD/agregarItemArrayDocVerificar";
import Seccion_Usuarios from "./Secciones/Seccion_Usuarios";
import Seccion_Avatares from "./Secciones/Seccion_Avatares";
import Seccion_Referencias from "./Secciones/Seccion_Referencias";
import Seccion_Retos from "./Secciones/Seccion_Retos";
import Seccion_Evidencias from "./Secciones/Seccion_Evidencias";
import Seccion_Kiosco from "./Secciones/Seccion_Kiosco";
import Seccion_Pedidos from "./Secciones/Seccion_Pedidos";

const Dashboard = () => {
    const cData = useContext(usuarioContext)
    const {Notificador} = useEscucharNotificacion()
    const {token} = useGetToken()


    const usuario = {
        nombre: 'Soporte Tecnico',
        tipo: 'Soporte Tecnico'
    }

    useEffect(() => {
        if (cData.usuario) {
            let usuario = cData.usuario
            if (token && usuario.token !== token) {
                agregarItemArrayDocVerificar({
                    col: 'tokens',
                    id: 'adminTokens',
                    nombreArray: 'tokens',
                    obj: token
                }).then((dox) => {
                    if (dox.res) {
                        //  console.log('token agregado')
                    }
                })
            }

        }

    }, [token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones}>
                <MenuLateral
                    logo={logo}
                    //clickPerfil={click}
                    logoRedondo={logo_redondo}
                    logoBlanco={logo_blanco}
                    usuario={usuario}
                    salir={funSalir}
                />
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Subastas',
        Componente: <Seccion_Subastas/>,
        icono: GavelOutlined,
        categoria: 'propia',
        camino: '/subastas',
    },
    {
        nombre: 'Pedidos Kiosco',
        Componente: <Seccion_Pedidos/>,
        icono: ShoppingCart,
        categoria: 'propia',
        camino: '/pedidos',
    },
    {
        nombre: 'Tickets',
        Componente: <Seccion_Tickets/>,
        icono: LocalActivity,
        categoria: 'Tickets',
        camino: '/tickets',
    },

    {
        nombre: 'Venta Tickets',
        Componente: <Seccion_Venta_Tickets/>,
        icono: Receipt,
        categoria: 'Tickets',
        camino: '/venta_tickets',
    },
    {
        nombre: 'Caja',
        Componente: <Seccion_Caja/>,
        icono: PointOfSale,
        categoria: 'Tickets',
        camino: '/Caja',
    },

    {
        nombre: 'Retos',
        Componente: <Seccion_Retos/>,
        icono: ThumbUpOutlined,
        categoria: 'retos',
        camino: '/Retos',
    },
    {
        nombre: 'Evidencias',
        Componente: <Seccion_Evidencias/>,
        icono: Checklist,
        categoria: 'retos',
        camino: '/Evidencias',
    },
    {
        nombre: 'Kiosco',
        Componente: <Seccion_Kiosco/>,
        icono: Storefront,
        categoria: 'retos',
        camino: '/Kiosco',
    },
    {
        nombre: 'Usuarios',
        Componente: <Seccion_Usuarios/>,
        icono: AccountCircle,
        categoria: 'listas',
        camino: '/Usuarios',
    },
    {
        nombre: 'Referencias',
        Componente: <Seccion_Referencias/>,
        icono: GroupAdd,
        categoria: 'listas',
        camino: '/Referencias',
    },
    {
        nombre: 'Avatares',
        Componente: <Seccion_Avatares/>,
        icono: EmojiEmotions,
        categoria: 'listas',
        camino: '/Avatares',
    },

]
