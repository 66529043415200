/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    Inventory,
    LocalActivity,
    LocalMall,
    LocalPolice,
    LocalShipping, MilitaryTech,
    Money,
    QrCode2,
    Link, AccountCircle
} from "@mui/icons-material";
import {useState} from "react";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";


const Formulario_evidencia_detalle = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                {props.isImagen ?
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <img src={props.img} width={'60%'} height={'auto'}/>
                    </Grid>
                    :
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <IngresoTexto Icono={Link} nombre={'Link de evidencia'} dato={'link'}
                                      {...props}

                        />
                    </Grid>

                }

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nick de Usuario'} dato={'nick'}
                                  editable={false}
                                  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={MilitaryTech} nombre={'Medallas a entregar'} type={'number'} dato={'numEntregar'}
                                  {...props}

                    />
                </Grid>



            </Grid>
        </form>

    )

}
export default Formulario_evidencia_detalle