/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_detalle_referenciado from "../Formularios/Formulario_detalle_referenciado";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import Formulario_reto_detalle from "../Formularios/Formulario_reto_detalle";
import Formulario_pedido_detalle from "../Formularios/Formulario_pedido_detalle";

const Dialogo_Pedido_Detalle = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            guardarDoc('pedidos', obj).then((dox) => {
                if (dox.res) {
                    alert('Cambios guardados con exito')
                    realizado()
                    cerrar()
                }
                cerrarCargador()
            })

        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Formulario_pedido_detalle props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    disabled={datos && datos.estado === 'Entregado'}
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Guardar Datos</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Pedido_Detalle