/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {Gavel, ManageSearch, ThumbUpAltOutlined} from "@mui/icons-material";
import {contarPropiedadSumaWheres} from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Referencia_Detalle from "../Dialogos/Dialogo_Referencia_Detalle";
import Dialogo_Reto_Detalle from "../Dialogos/Dialogo_Reto_Detalle";
import Dialogo_Evidencia_Detalle from "../Dialogos/Dialogo_Evidencia_Detalle";
import {contarColeccionWhere} from "../../Servicios/BD/Contadores/contarColeccionWhere";

const Seccion_Evidencias = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numEvidenciasEnviadas, setNumEvidenciasEnviadas] = useState(0)
    const [numEvidencias, setNumEvidencias] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'evidencias',
        filtroInicial: 'fecha',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Evidencia_Detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de evidencia'
    })


    const BotonesTitulo = []

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
        }

    }


    useEffect(() => {

        let w = [where('estado', '==', 'Enviado')]
        contarColeccionWhere({coleccion: 'evidencias', wheres: w}).then((dox) => {
            if (dox.res) {
                setNumEvidenciasEnviadas(dox.data)
            }
        })

        contarColeccion({coleccion: 'evidencias'}).then((dox) => {
            if (dox.res) {
                setNumEvidencias(dox.data)
            }
        })


        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Retos'} textoComplementario={'enviadas'}
                                      textoResaltado={`${numEvidenciasEnviadas} Evidencias`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numEvidencias}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Evidencias

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nick',
        tipo: 'fechaDato'
    },
    {
        nombre: 'Reto',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',
    },

]


const datosCabeceraCell = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nick',
        tipo: 'fechaDato'
    },
    {
        nombre: 'Reto',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',
    },
]

const botonesTabla = [
    {
        nombre: 'Ver',
        icono: ManageSearch,
        id: 'ver'
    }

]



